var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistics view"},[_c('app-header',{attrs:{"title":'User Statistics'}}),(_vm.loading)?_c('progress-linear'):_vm._e(),_c('div',{staticClass:"control-bar",class:{'control-bar--disabled': _vm.loading}},[_c('div',{staticClass:"control-bar__buttons"},[_c('div',{directives:[{name:"slashes",rawName:"v-slashes"}],staticClass:"control-bar__button",attrs:{"title":_vm.__('common.refresh')},on:{"click":_vm.refresh}},[_c('img',{staticClass:"control-bar__button-icon",attrs:{"src":"https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg","alt":"refresh"}})])])]),_c('div',{staticClass:"main-content-padding"},[_c('div',{staticClass:"layout layout--wrap"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(_vm.hasOneRoles(['Member']))?[_vm._m(4),_vm._m(5),_vm._m(6)]:_vm._e()],2)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"registrations_global"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm6 md3 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"email_confirmed_global"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm6 md3 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"locked_out_global"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm12 md6 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"activity_global"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm6 md3 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"email_confirmed_member"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm6 md3 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"locked_out_member"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs12 sm12 md6 mt"},[_c('div',{staticClass:"tile tile--no-padding"},[_c('div',{attrs:{"id":"activity_member"}})])])
}]

export { render, staticRenderFns }