<template>
  <div class="statistics view">
    <app-header :title="'User Statistics'"/>
    <progress-linear v-if="loading"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="main-content-padding">
      <div class="layout layout--wrap">
        <div class="flex xs12">
          <div class="tile tile--no-padding">
            <div id="registrations_global"></div>
          </div>
        </div>
        <div class="flex xs12 sm6 md3 mt">
          <div class="tile tile--no-padding">
            <div id="email_confirmed_global"></div>
          </div>
        </div>
        <div class="flex xs12 sm6 md3 mt">
          <div class="tile tile--no-padding">
            <div id="locked_out_global"></div>
          </div>
        </div>
        <div class="flex xs12 sm12 md6 mt">
          <div class="tile tile--no-padding">
            <div id="activity_global"></div>
          </div>
        </div>
        <template v-if="hasOneRoles(['Member'])">
          <div class="flex xs12 sm6 md3 mt">
            <div class="tile tile--no-padding">
              <div id="email_confirmed_member"></div>
            </div>
          </div>
          <div class="flex xs12 sm6 md3 mt">
            <div class="tile tile--no-padding">
              <div id="locked_out_member"></div>
            </div>
          </div>
          <div class="flex xs12 sm12 md6 mt">
            <div class="tile tile--no-padding">
              <div id="activity_member"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts/highcharts';
import Wordcloud from 'highcharts/modules/wordcloud';
import theme from '../../library/highcharts/theme';
import apiSecured from '../../api/secured';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { pageTitleMixin } from "../../mixins/pageTitle";
import { userMixin } from "../../mixins/user";

const CHART_HEIGHT = 300;
const AXIS_PADDING_FACTOR = 1.0;

Highcharts.setOptions(theme);
Wordcloud(Highcharts);

function notUndefined(value) {
  if (value === undefined) {
    return 0;
  }
  return value;
}

export default {
  mixins: [notificationsMixin, localizationMixin, pageTitleMixin, userMixin],
  components: {
    AppHeader
  },
  data: () => ({
    loading: false,
    stats: {},

    positiveColor: '#01d68e',
    negativeColor: '#cc062e',
  }),
  methods: {

    renderActivity(area) {
      Highcharts.chart(`activity_${area}`, {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column'
        },
        title: {
          text: `Active users (total: ${this.stats[area].totalUsers}) ${area}`
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '10px'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {}
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: 'Count: <b>{point.y:.1f}</b>'
        },
        series: [{
          name: 'Activity',
          data: [
            { name: 'never logged in', y: this.stats[area].neverLoggedIn, color: this.negativeColor },
            { name: 'last 30 days', y: this.stats[area].activeLast30Days },
            { name: 'last 14 days', y: this.stats[area].activeLast14Days },
            { name: 'last 7 days', y: this.stats[area].activeLast7Days },
            { name: 'last 24 hour', y: this.stats[area].activeLast24Hours },
          ],
          dataLabels: {
            enabled: false,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '16px'
            }
          }
        }]
      });
    },
    renderLockedOut(area) {
      const lockedOutData = [
        { name: 'locked out', y: this.stats[area].lockedOut, sliced: true, color: this.negativeColor },
        {
          name: 'not locked',
          y: this.stats[area].totalUsers - this.stats[area].lockedOut,
          sliced: false,
          color: this.positiveColor
        }
      ];
      Highcharts.chart(`locked_out_${area.toLowerCase()}`, {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: `Locked out ${area}`
        },
        tooltip: {
          pointFormat: '<b>{point.y:.1f}</b>'
        },
        legend: {
          show: true
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            },
            showInLegend: true
          }
        },
        series: [{
          name: 'Locked out',
          colorByPoint: true,
          data: lockedOutData
        }]
      });
    },
    renderEmailConfirmed(area) {
      const emailConfirmedData = [
        { name: 'not confirmed', y: this.stats[area].emailNotConfirmed, sliced: true, color: this.negativeColor },
        { name: 'confirmed', y: this.stats[area].emailConfirmed, sliced: false, color: this.positiveColor }
      ];
      Highcharts.chart(`email_confirmed_${area}`, {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: `E-Mail confirmed ${area}`
        },
        tooltip: {
          pointFormat: '<b>{point.y:.1f}</b>'
        },
        legend: {
          show: true
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            },
            showInLegend: true
          }
        },
        series: [{
          name: 'Email confirmed',
          colorByPoint: true,
          data: emailConfirmedData
        }]
      });
    },
    renderRegistrationsPublic() {
      Highcharts.chart('registrations_global', {
        credits: {
          enabled: false,
        },
        chart: {
          type: 'line'
        },
        title: {
          text: 'Registrations per day'
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '10px'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {}
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: 'Count: <b>{point.y:.1f}</b>'
        },
        series: [{
          name: 'Registrations',
          data: Object.keys(this.stats.global.registrationsByDate).map(key => [key, this.stats.global.registrationsByDate[key]]),
          dataLabels: {
            enabled: false,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: '16px'
            }
          }
        }]
      });
    },
    refresh() {
      this.loading = true;
      apiSecured.get('/identity/userstats').then((res) => {
        this.stats = res.data;
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
        this.renderRegistrationsPublic();
        this.renderEmailConfirmed('global');
        this.renderLockedOut('global');
        this.renderActivity('global');
        if(this.hasOneRoles(['Member'])) {
          this.renderEmailConfirmed('member');
          this.renderLockedOut('member');
          this.renderActivity('member');
        }

      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
  },
  created() {
    this.setPageTitle('User Statistics');
    this.refresh();
  }
}
</script>
